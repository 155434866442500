<template>
  <b-tab title="Sản phẩm đã ghép">
    <div>
      <CustomTable
        :items="items"
        :fields="fields"
        custom-link="product/edit"
        :add_new_button="false"
      />
    </div>
    <!-- <div v-else>
      <no-data />
    </div> -->
  </b-tab>
</template>
<script>
import {
  BTab,
} from 'bootstrap-vue'
import general from '@/mixins/index'

export default {
  components: {
    BTab,
  },
  mixins: [general],
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        { key: 'avatar', label: 'Avatar', sortable: false },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'category_name', label: 'Category on Shopee', sortable: true },
        { key: 'sku', label: 'Sku', sortable: true },
      ],
      items: [],
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.items = val
      }
    },
  },
  mounted() {
    this.items = this.list
  },
}
</script>
