<template>
  <b-tab title="Sản phẩm chưa ghép">
    <div>
      <CustomTable
        :items="items"
        :fields="fields"
        :custom-link="`channel/sync-${channel}`"
        :channel="channel"
        :add_new_button="false"
      />
    </div>
    <!-- <div v-else>
      <no-data />
    </div> -->
  </b-tab>
</template>

<script>
import {
  BTab,
} from 'bootstrap-vue'
// import Request from '@/plugins/service/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    channel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fields: [
        // { key: 'avatar', label: 'Avatar', sortable: false },
        { key: 'avatar_name', label: 'Product', sortable: true },
        // { key: 'sku', label: 'SKU (Shopee)', sortable: true },
        { key: 'category_name', label: 'Category', sortable: true },
        { key: 'stock1', label: 'Stock', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'sync', label: 'Sync', sortable: false },
      ],
      items: [],
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.items = val
      }
    },
  },
  mounted() {
    this.items = this.list
  },
}
</script>
