<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <div
    v-if="!connected"
    class="w-75 mt-4 mx-auto"
  >
    <b-row>
      <b-col cols="6" class="my-auto">
        <div>
          <h2>Kết nối kênh bán hàng Tiktok</h2>
          <p class="text-primary">
            Kết nối hệ thống của IFashion và Tiktok Shop, giúp đẩy sản phẩm trực tiếp lên Tiktok Shop cũng như đồng bộ về tồn kho, đơn hàng.
          </p>
          <p>
            Kênh bán hàng Tiktok Shop của bạn chưa được kích hoạt. Vui lòng kích hoạt để bắt đầu sử dụng.
          </p>
          <b-button
            variant="primary"
            class="btn-icon rounded"
            @click="handleConnect"
          >
            {{ $t('Kích hoạt kênh bán hàng') }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <img src="/banner_tiktok.png" alt="Tiktok">
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-tabs
      content-class="pt-1"
    >
      <NotSyncProduct
        :list="notSyncProductList"
        channel="tiktok"
      />
      <SyncProduct
        :list="syncProductList"
        channel="tiktok"
      />
    </b-tabs>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BButton,
  BTabs,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Request from '@/plugins/service/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotSyncProduct from './components/NotSyncProduct.vue'
import SyncProduct from './components/SyncProduct.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTabs,
    NotSyncProduct,
    SyncProduct,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      channelDetail: null,
      connected: false,
      channels: [
        {
          id: 1,
          name: 'Lazada',
          des: 'Connecting Buso and Lazada systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Lazada',
          img: require('@/assets/images/salesChannel/lazada.svg'),
          connected: false,
          link: 'channel/lazada',
          count: 0,
        },
        {
          id: 2,
          name: 'Shopee',
          des: 'Connecting Buso and Shopee systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Shopee',
          img: require('@/assets/images/salesChannel/shopee.svg'),
          connected: false,
          link: 'channel/shopee',
          count: 0,
        },
        {
          id: 3,
          name: 'Tiki',
          des: 'Connecting Buso and Tiki systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Tiki',
          img: require('@/assets/images/salesChannel/tiki.svg'),
          connected: false,
          link: 'channel/tiki',
          count: 0,
        },
        {
          id: 4,
          name: 'Facebook Live',
          des: 'Connecting Buso and Facebook Live systems, helping to synchronize inventory and prices, orders between the website system on Buso and your livestream on Facebook',
          img: require('@/assets/images/salesChannel/facebook.svg'),
          connected: false,
          link: '/setting-fb',
          count: 0,
        },
      ],
      notSyncProductList: [],
      syncProductList: [],
    }
  },
  computed: {
    miniList() {
      const temp = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.channels.length - 1; i++) {
        temp.push(this.channels[i])
      }
      return temp
    },
  },
  mounted() {
    this.loadSiteInfo()
  },
  methods: {
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            if (res.data.data.token_tiktok !== '' && res.data.data.token_tiktok !== null && res.data.data.token_tiktok !== undefined) {
              this.connected = true
            } else if (this.$route.query.code) {
              await this.handlerUpdate(this.$route.query.code)
            }
            this.loadSyncProduct()
            this.loadLazadaProduct(res.data.data.id)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChannelDetail(data) {
      this.channelDetail = data
    },
    // async handlerSubmit(data) {
    //   if (data.id === 1) {
    //     try {
    //       const res = await Request.get(
    //         this.$http,
    //         `${process.env.VUE_APP_API_URL}/lazada/auth_partner?mode=sandbox&path=${window.location.origin}/channel/lazada`,
    //       )
    //       if (res.status === 200) {
    //         if (res.data.status) {
    //           window.location.href = res.data.data.link
    //         }
    //       }
    //     } catch (error) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Opps! Something wrong',
    //           icon: 'AlertOctagonIcon',
    //           variant: 'danger',
    //           text: String(error),
    //         },
    //       })
    //     }
    //   }
    //   if (data.id === 2) {
    //     try {
    //       const res = await Request.get(
    //         this.$http,
    //         `${process.env.VUE_APP_API_URL}/shopee/auth_partner?mode=production&path=${window.location.origin}/channel/shopee`,
    //       )
    //       if (res.status === 200) {
    //         if (res.data.status) {
    //           window.location.href = res.data.data.link
    //         }
    //       }
    //     } catch (error) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Opps! Something wrong',
    //           icon: 'AlertOctagonIcon',
    //           variant: 'danger',
    //           text: String(error),
    //         },
    //       })
    //     }
    //   }
    //   if (data.id === 4) {
    //     try {
    //       const res = await Request.get(
    //         this.$http,
    //         `${process.env.VUE_APP_API_URL}/facebook/get_oauth?type=1&redirect_uri=${window.location.origin}/channel/facebook-live`,
    //       )
    //       if (res.status === 200) {
    //         if (res.data.status) {
    //           window.location.href = res.data.data.url
    //         }
    //       }
    //     } catch (error) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Opps! Something wrong',
    //           icon: 'AlertOctagonIcon',
    //           variant: 'danger',
    //           text: String(error),
    //         },
    //       })
    //     }
    //   }
    // },
    async handleConnect() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/tiktok/v1/get_auth_code`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            window.location.href = res.data.data.link
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerUpdate(code) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/tiktok/v1/get_access_token?auth_code=${code}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const temp = {
              access_token: res.data.data.access_token,
              refresh_token: res.data.data.refresh_token,
            }
            const resShopId = await Request.get(
              this.$http,
              `${process.env.VUE_APP_API_URL}/tiktok/v1/get_authorized_shop?access_token=${res.data.data.access_token}`,
            )
            if (resShopId.status === 200) {
              if (resShopId.data.status) {
                temp.shop_id = resShopId.data.data.shop_list[0].shop_id
                const params = {
                  // name: this.model.name,
                  is_active: 1,
                  token_tiktok: JSON.stringify(temp),
                }
                const resSite = await Request.put(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
                  params,
                )
                if (resSite.status === 200) {
                  if (resSite.data.status) {
                    this.connected = true
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Opps! Something wrong',
                        icon: 'AlertOctagonIcon',
                        variant: 'danger',
                        text: String(this.showError(resSite.data.error, ',')),
                      },
                    })
                  }
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: 'Không lấy được thông tin Shop ID Tiktok, vui lòng thử lại sau!',
                  },
                })
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadLazadaProduct(siteID) {
      this.isShow = true
      try {
        await this.handleCategory(siteID)
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/tiktok/v1/get_product_list?site_id=${siteID}&access_token=
ROW_rNY9yAAAAADbetz5u_UYQE3udBOPUn-K2FWSGKr9jRQ2OLpAjKuuFBSOpamHs2Htio8okZ64r32260odxY8SrgbJgJJW_2Dq3nA6fIfdowDmOqQYeBgrLg&shop_id=7494530586889194270&page_size=20&page_number=1`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.products.map(async val => {
              const findIndex = this.syncProductList.findIndex(x => Number(x.code_e_commerce) === val.item_id)
              if (findIndex < 0) {
                const resDetail = await Request.get(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/tiktok/v1/get_product_detail?product_id=${val.id}&site_id=${siteID}&access_token=
ROW_rNY9yAAAAADbetz5u_UYQE3udBOPUn-K2FWSGKr9jRQ2OLpAjKuuFBSOpamHs2Htio8okZ64r32260odxY8SrgbJgJJW_2Dq3nA6fIfdowDmOqQYeBgrLg&shop_id=7494530586889194270`,
                )
                if (resDetail.status === 200) {
                  if (resDetail.data.status) {
                    const item = {
                      id: resDetail.data.data.product_id,
                      avatar: resDetail.data.data.images[0].thumb_url_list[0],
                      name: resDetail.data.data.product_name,
                      sku: resDetail.data.data.skus[0].SellerSku,
                      category_id: resDetail.data.data.category_id,
                      stock1: 0,
                    }
                    // eslint-disable-next-line array-callback-return
                    resDetail.data.data.skus.map(x => {
                      item.stock1 += x.stock_infos[0].available_stock
                    })
                    this.notSyncProductList.push(item)
                  }
                }
              }
            })
          }
        }
        this.isShow = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
    async loadSyncProduct() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&e_commerce=2`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.syncProductList = await this.getDataTranslation(res.items, true)
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleCategory(siteID) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/lazada/get_categories?site_id=${siteID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categories = res.data.data
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
//   async mounted() {},
//   methods: {},
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
